import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Media from '../app-media/Media';
import './style.css';
import icon192 from './icon-192x192.png';

function Rrss() {    
  const [iPais, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);

  useEffect(() => {
    Axios.post(`https://cloud.tupediste.com/location/ip`)
      .then((response) => {
        if (response.data) {
          // Extraemos los datos de la API correctamente
          const { country, city } = response.data;
          setCountry(country);
          setCity(city);
        } else {
          const country = '.';
          const city = '.';
          setCountry(country);
          setCity(city);
          console.error('Localization Not');
        }
      })
      .catch((error) => {
        const country = '.';
        const city = '.';
        setCountry(country);
        setCity(city);
        console.error('Ups, Algo salió mal en Location');
      });
  }, []);

  return (
    <div className="iRRSS">
      <div className="iBox-media">
      <span className="tp-text-w18">Siguenos</span>
        <Media />
      </div>
      <div className="iRRSS-column">
        <div className="iRRSS-pie">
          <div className='iRRSS-pie-l'>
            <span className="tp-text-w12">Iglesiavrf&nbsp;&copy;&nbsp;2024</span>
          </div>
          <div className='iRRSS-pie-r'>
            <span className="tp-text-w12"> {iPais ? iPais : 'Esperando...'}, &nbsp; {iCiudad ? iCiudad : 'Cargando...'} </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rrss;
