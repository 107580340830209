import React from 'react';
import IN from './IN.png';
import YT from './YT.png';
import FB from './FB.png';

function Media() {
  return (
<div>
    <div>
      <a href="https://www.instagram.com/stories/vision.restauracionfamiliar/3450020717859190968?igsh=d280NW5wMGx6dTNm" target="_blank" rel="noopener noreferrer">
          <img className="iBtns-madia" src={IN} alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/share/wHrNXL7efygAix5L/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
          <img className="iBtns-madia" src={FB} alt="facebook" />
      </a>
    </div>
</div>
  );
}

export default Media;