import logo from './icono.png';

function Header() {
  return (
<header className="tp-header">
  <a href="/" alt="Inicio"><img src={logo} className="imagicon" alt="VRF"/></a>
</header>
  );
}

export default Header;