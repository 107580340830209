import './Wapp.css'
// Images
import Ws from '../../assets/sicons/Wapp.png'

function Wapp() {
    return (
        <>
            <a title="Escribenos" href="https://wa.me/+573006776210?text=Hola,%20Quisiera%20Saber%20mas%20de%20ustedes" class="float" target="_blank">
                <img src={Ws} width={60}/>
            </a>
        </>
    );
}

export default Wapp;