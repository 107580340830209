import React from 'react';
import './Dicipular.css';

function Media() {
  return (
<div className="iDicipular">
    <div className="Box-title-center tp-textb-w18">
        <span>Ministerio Dicipular IVRF</span>
    </div>
    <div className="iDicipulado-center">
        <div className="iDicipulado-l" data-aos="zoom-out-down" data-aos-duration="1500"><img src="https://cdn.iglesiavrf.org/todolugar.png" alt="Familia" className="iDicipulado-img" /></div>
        <div className="iDicipulado-r">
            <div className="iDicipulado-card">
                <span className="">Ganar personas para <b>Jesus Cristo</b> en todo lugar y en todo momento.</span>
            </div>
            <div className="iDicipulado-card">
                <span className=""> Toda persona que se prepará con bocación para servir y amar a Dios, lo hará igual con su progimo.  </span>
            </div>
            <div className="iDicipulado-card">
                <span className="">Capaz de ganar a otros para el reino de Dios, con gran voluntad junto a nuestros excelentes lideres pueden convertirte en un ser unico como instrumento para ganar el Reino de Dios.
                </span>
            </div>
        </div>
    </div>
</div>
  );
}

export default Media;

