import React, { useState, useEffect } from 'react';
import './style.css';

function Head() {
    const [useMobileComp, setUseMobileComponent] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setUseMobileComponent(window.innerWidth < 520);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
<div className="iHead">
  <img src="https://cdn.iglesiavrf.org/Familia.png" alt="Familia" className="iHead-image" data-aos="fade-right"
     data-aos-offset="500"
     data-aos-easing="ease-in-sine" />
    <div className="iHead-back">
                <div className="iHead-msj-float" data-aos="zoom-out-down" data-aos-duration="1500">
                    <span className="title-h1-nblack tclamp-14-4-32">Visítanos, Dios espera por tí</span>
                </div>
        <div className="iHead-back-center">
            <div className="iHead-back-l" data-aos="zoom-in-down" data-aos-duration="900" data-aos-delay="0">
                <div className="iHead-msj-l tp-textb-w14">
                    Genesis 12-3
                    <p>Y serán benditas en tí, todas las familias de la tierra.</p>
                </div>
            </div>
            <div className="iHead-back-r">
                <div className="iHead-msj-r" {...(!useMobileComp && { 'data-aos': 'zoom-in-up', 'data-aos-duration': '1200', 'data-aos-delay': '200', })}>
                    <h2 className="title-h1-bblack">Nuestra Visión</h2>
                    <p className="title-h1-nblack"> Atraer a las familias mediante el amor del Padre para formar el carácter de Cristo en ellos y reproducir adoradores tanto en espíritu así como en verdad. </p>
                </div>
                <a href="#Reuniones" className="Btn-Slred" >REUNIONES Y HORARIOS</a>
            </div>
        </div>
    </div>
</div>
  );
}

export default Head;
