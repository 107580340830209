import React, { useEffect, lazy, Suspense } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './App.css';
import Header from './components/sample-header/Header';
import Head from './components/app-head/Head';
import Text from './components/app-text/Text';
import Rrss from './components/app-rrss/Rrss';
import Dicipular from './components/app-dicipular/dicipular';
import Kieds from './components/app-kid/Kid';
import Juvenil from './components/app-juvenil/Juvenil';
import Reunion from './components/app-reuniones/Reunion';
import Wapp from './components/app-wapp/Wapp';

const LoadingComponent = lazy(() => import('./Middleware/Loading'));

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
<div className="tp-g-content">
  <Suspense fallback={<LoadingComponent />}>
    <Header />
      <div className="tp-container">
        <Head />
        <Text />
        <Dicipular />
        <Juvenil />
        <Kieds />
        <Reunion />
      </div>
      <Wapp />
      <Rrss />
  </Suspense>
</div>
  );
}

export default App;

