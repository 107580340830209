import React from 'react';
import './Juvenil.css';

function Juvenil() {
  return (
<div className="iJuvenil">
        <div className="iJuvenil-l">
            <div className="Box-title-center-ju tp-textb-w18"> <span>Ministerio JUVENIL</span> </div>
            <div className="tp-p-1 tp-p-justy">
                <span className="Txt-Juvenil-xgre">Un Espacio para Crecer </span><span className="Txt-Juvenil">en nuestra congregación, está diseñado para ayudar a los adolescentes a crecer en su fe, fortaleciendo su relación con Dios y capacitándolos para enfrentar los desafíos de la vida con sabiduría y valentía. A través de actividades dinámicas, enseñanza bíblica y un ambiente de compañerismo, buscamos fomentar el desarrollo integral de cada joven</span>
            </div>
            <div className="tp-p-1 tp-p-justy">
                <span className="Txt-Juvenil">Los jóvenes que participan en nuestro ministerio experimentarán un proceso de transformación genuino en ellos, así como </span> <span className="Txt-Juvenil-xgre"> Crecimiento Espiritual y Personal </span><span className="Txt-Juvenil">ademas también emocional y social. Aprenderán a aplicar los principios bíblicos en su vida diaria, a confiar más en Dios en tiempos difíciles, y a vivir con integridad y fe en todas las áreas de su vida.</span>
            </div>
            <img src="https://cdn.iglesiavrf.org/salto.png" alt="Kieds" className="iSalto-img" data-aos="fade-up-right" data-aos-anchor-placement="bottom-bottom"/>
        </div>
        <div className="iJuvenil-r" data-aos="fade-down-left" data-aos-duration="1500">
            <img src="https://cdn.iglesiavrf.org/Juvenil.png" alt="Juvenil" className="iJuvenil-img" />
        </div>
</div>
  );
}

export default Juvenil;