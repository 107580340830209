import React from 'react';
import './Kid.css';

function Kid() {
  return (
<div className="iKids title-h1-bwhite-kids">
<div className="Box-title-center-kid tclamp-32"> <span>Ministerio para Niños</span> </div>
    <div className="iKids-sections">
        <p className="tclamp-18-4-32">Dejen que los niños vengan a mí, y no se lo impidan, porque el reino de los cielos es de quienes son como ellos (Mateo 19:14)</p>
    </div>    
  <img src="https://cdn.iglesiavrf.org/kids.png" alt="Kieds" className="iKid-img" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom"/>
</div>
  );
}

export default Kid;