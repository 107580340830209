import React from 'react';
import './Reunion.css';

function Reunion() {
  return (
<div className="iReunion" id="Reuniones">
    <div className="tp-textb-w18">
        <span className="tclamp-32-n">Reuniones y Horarios</span>
    </div>
    <div className="iReunion-info">
            <div className="iDicipulado-card">
                <span className=""><b>Miercoles</b></span>
                <p className="">Sanidad y Milagros <br/> <b>6:30pm</b> </p>
            </div>
            <div className="iDicipulado-card">
                <span className=""><b>Sabados</b></span>
                <p className="">Ayunos <br/><b>9:00am a 12:00pm</b> </p>
            </div>
            <div className="iDicipulado-card">
                <span className=""><b>Domingos</b></span>
                <p className="">Reunión Familiar <br/> <b>9:00am y a las 11:30am</b> </p>
            </div>
            <div className="iDicipulado-card">
                <span className=""><b>Vigilias</b></span>
                <p className="">Ultimo <b>viernes</b> de cada mes. </p>
            </div>
    </div>
    <div className="w-50-100 tp-p-1 tp-p-justy">
        <p>Fortalecer un buen hábito te da estabilidad y te ayuda a alcanzar metas de forma constante. Mejora tu bienestar físico, mental y espiritual, desarrollando autodisciplina y equilibrio en tu vida. Permítenos ayudarte a conseguirlo con las herramientas que Dios ha colocado en tu camino.</p>
    </div>
</div>
  );
}

export default Reunion;