import React from 'react';

function Text() {
  return (
<div className="iSecund-content">
        <div className="iSecund-center">
                    <h1>CONOCE NUESTROS MINISTERIOS Y SÉ PARTE DE UNA NUEVA GENERACIÓN</h1>
                    <p className="title-h1-nblack">Encontrar en el espiritu los dones que Dios a puesto en ti, es lo más importante para comenzar a servir a él y en su Reino, siendo de herramienta en su gloria y de bendición a otros.</p>
        </div>
</div>
  );
}

export default Text;